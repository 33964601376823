import React, { useState, useEffect, useRef } from "react";
import "./ChatButton.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";

const ChatButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [imageName, setImageName] = useState("");
  const [fullscreen, setFullscreen] = useState(false);
  const [threadId, setThreadId] = useState(0);
  const fileInput = useRef();
  const textInputRef = useRef(null);
  const [abortController, setAbortController] = useState(null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };
  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    if (!isSending && textInputRef.current &&isOpen) {
      textInputRef.current.focus();
    }
    
  }, [isSending, isOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        const root = document.documentElement;
        root.style.setProperty(
          "--button-options-background",
          data.colors.buttonOptionsBG
        );
        root.style.setProperty(
          "--chat-header-bg-color",
          data.colors.chatHeaderBgColor
        );
        root.style.setProperty("--chat-bg-color", data.colors.chatBgColor);
        root.style.setProperty("--font-size", data.colors.fontSize);
        root.style.setProperty("--font-type", data.colors.fontType);
        root.style.setProperty(
          "--message-user-bg-color",
          data.colors.messageUserBgColor
        );
        root.style.setProperty(
          "--message-bot-bg-color",
          data.colors.messageBotBgColor
        );
        root.style.setProperty("--mainColor", data.colors.mainColor);
        root.style.setProperty(
          "--image-button-bg-color",
          data.colors.ImageButtonBG
        );
        root.style.setProperty(
          "--header-title-color",
          data.colors.headerTitleColor
        );
        root.style.setProperty(
          "--input-container-bg",
          data.colors.inputContainerBg
        );
        root.style.setProperty(
          "--header-fontweight",
          data.colors.headerFontWeight
        );
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);

  const handleReset = () => {
    setMessages([]);
    setThreadId(0);
    setIsInputDisabled(false);

  };

  const handleClick = () => {
    const chatContainer = document.querySelector(".chat-container");
    chatContainer.classList.remove("fullscreen-chat-container");
    setIsOpen(!isOpen);
    if (!isOpen) {
    }
  };

  const toggleFullScreen = () => {
    const chatContainer = document.querySelector(".chat-container");
    const chatWindow = document.querySelector(".chat-window");
    const chatHeader = document.querySelector(".chat-header");
    const inputContainer = document.querySelector(".input-container");

    chatContainer.classList.toggle("fullscreen-chat-container");
    chatWindow.classList.toggle("fullscreen");
    chatHeader.classList.toggle("fullscreen-no-radius");
    inputContainer.classList.toggle("fullscreen-no-radius");
    setFullscreen(!fullscreen);
  };

  const guardarLogs = (messages) => {
    if (messages.length > 0) {
      // Create a plain text log of all messages
      const messageData = messages
        .map((msg) => {
          return `Remitente: ${msg.sender}\nTexto: ${msg.text}\nFecha: ${msg.timestamp}\n\n`;
        })
        .join("");

      const blob = new Blob([messageData], {
        type: "text/plain;charset=utf-8",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "messageLogs.txt";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  const handleSendMessage = (message, imageUrl) => {
    let now = new Date();
    let timestamp = now.toLocaleString();

    if (imageUrl) {
      setMessages([
        ...messages,
        { text: imageUrl, sender: "user", isImage: true, timestamp: timestamp },
        { text: message, sender: "user", isImage: false, timestamp: timestamp },
      ]);
      sendImage(imageUrl, message);
      setIsImageSelected(false);
      fileInput.current.value = null;
    } else if (message.trim().length > 1) {
      setMessages([
        ...messages,
        { text: message, sender: "user", isImage: false, timestamp: timestamp },
      ]);
      sendText(message);
    }
  };

  const sendMessage = (e) => {
    let text = textInputRef.current.value;
    let file = fileInput.current.files[0];
    textInputRef.current.value = "";

    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        handleSendMessage(text, reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      handleSendMessage(text);
    }
  };

  const sendText = async (message) => {
    setIsSending(true);

    const controller = new AbortController();
    setAbortController(controller);

    try {
      const response = await fetch(
        `https://127.0.0.1:5000/api/maestro/proveedores/pregunta`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message, thread_id: threadId }),
          signal: controller.signal,
        }
      );
      if (!response.ok) {
        throw new Error("Error in server response: " + response.statusText);
      }
      const data = await response.json();
      setThreadId(data.thread_id);
      let now = new Date();
      let timestamp = now.toLocaleString();
      
      setMessages((oldMessages) => [
        ...oldMessages,
        { text: data.message, sender: "bot", timestamp: timestamp },
      ]);
    } catch (error) {
      if (error.name !== "AbortError") {
        const errorMessage = `Error: ${
          error.message || error.toString()
        } [Reset Chat]`;
        setMessages((oldMessages) => [
          ...oldMessages,
          { text: errorMessage, sender: "bot" },
        ]);
        setIsInputDisabled(true);
      }
    } finally {
      setIsSending(false);
      setAbortController(null);
    }
  };

  const sendImage = async (imagestr, recievedText) => {
    const api_key = "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";

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    };

    const payload = {
      model: "gpt-4-vision-preview",
      messages: [
        {
          role: "system",
          content:
            "You are an assistant and you will always answer with the minimum words possible. Never ask anything, just answer what is sent.",
        },
        {
          role: "user",
          content: [
            { type: "text", text: recievedText },
            {
              type: "image_url",
              image_url: {
                url: imagestr,
                detail: "low",
              },
            },
          ],
        },
      ],
      max_tokens: 200,
    };

    fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessages((oldMessages) => [
          ...oldMessages,
          { text: data.choices[0].message.content, sender: "bot" },
        ]);
      })
      .catch((error) => {
        setMessages((oldMessages) => [
          ...oldMessages,
          { text: error.message, sender: "error" },
        ]);
        console.error("Error:", error);
      });
  };

  return (
    <div className="chat-container">
      {!isOpen && (
        <button className="chat-button" onClick={handleClick}>
          Chat
        </button>
      )}
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <div className="header-section">
              <img
                src="/images/a3erp.avif"
                className="header-image"
                alt="A3 ERP"
              />
            </div>
            <div className="header-section">
              <span className="header-title">A3 COPILOT</span>
            </div>
            <div className="header-section menu">
              <button className="fullscreen-button" onClick={toggleFullScreen}>
                {fullscreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
              </button>
              <button className="menu-button" onClick={toggleMenu}>
                <MoreVertSharpIcon></MoreVertSharpIcon>
              </button>
              <div
                className={`${
                  fullscreen ? "menu-options-fullscreen" : "menu-options"
                } ${menuOpen ? "show" : ""}`}
              >
                <button className="back-button" onClick={handleClick}>
                  Back
                </button>
                <button className="reset-button" onClick={handleReset}>
                  Reset Chat
                </button>
                <button
                  className="guardar-logs"
                  onClick={() => guardarLogs(messages)}
                >
                  Guardar Logs
                </button>
              </div>
            </div>
          </div>

          <div className="message-container" ref={messagesEndRef}>
            {[...messages].reverse().map((message, index) => (
              <div
              key={index}
              className={`message ${message.sender === "user" ? "user-message" : "bot-message"}`}
              title={`Sent at  ${message.timestamp}`} 
            >
                <img
                  className="message-image"
                  src={
                    message.sender === "user"
                      ? "/images/userLogo.png"
                      : "https://cdn.oaistatic.com/_next/static/media/favicon-32x32.be48395e.png"
                  }
                  alt="User or Bot Icon"
                />
                {message.isImage ? (
                  <div className="message-text">
                    <img src={message.text} alt="User uploaded image" />
                  </div>
                ) : (
                  <div
                    className="message-text"
                    dangerouslySetInnerHTML={{
                      __html: message.text.replace("[Reset Chat]", ""),
                    }}
                  />
                )}
                {message.text.includes("[Reset Chat]") && (
                  <button className="reset-button" onClick={handleReset}>
                    Reset Chat
                  </button>
                )}
              </div>
            ))}
            <div className="loading-indicator">
              {isSending && (
                <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                  <CircularProgress color="info" />
                </Stack>
              )}
            </div>
          </div>

          <div className="input-container">
            <input
              type="file"
              accept="image/*"
              className="file-input"
              ref={fileInput}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  setIsImageSelected(true);
                }
              }}
            />
            <button
              className={`upload-button ${isImageSelected ? "selected" : ""}`}
              onClick={() => fileInput.current.click()}
            >
              <AttachFileIcon></AttachFileIcon>
            </button>
            <input
              type="text"
              className="text-input"
              disabled={isSending ||isInputDisabled}
              ref={textInputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessage(e);
                }
              }}
            />

            {isSending && abortController ? (
              <button
                className="cancel-button"
                onClick={() => abortController.abort()}
              >
                <CancelIcon />
              </button>
            ) : (
              <button
                className="send-button"
                onClick={(e) => sendMessage(e)}
                disabled={isSending}
              >
                <SendSharpIcon />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default ChatButton;
